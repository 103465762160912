$gutter: 1rem; //for primeflex grid system

/* Layout */
@import 'assets/layout/styles/layout/layout.scss';

/* PrimeNG */
@import '../../../node_modules/primeng/resources/primeng.min.css';
@import '../../../node_modules/primeflex/primeflex.scss';
@import '../../../node_modules/primeicons/primeicons.css';

/* Demos */
@import 'assets/demo/styles/flags/flags.css';
@import 'assets/demo/styles/badges.scss';
@import 'assets/demo/styles/code.scss';

@mixin scrollbars(
  $size,
  $foreground-color,
  $background-color: mix($foreground-color, white, 50%)
) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}
body {
  @include scrollbars(0.4em, #cccccc, #f7f7f7);
}
* {
  @include scrollbars(0.4em, #cccccc, #f7f7f7);
}
